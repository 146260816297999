import { motion, useInView } from "framer-motion";
import IntroBishopImg from "../../assets/images/intro/intro_bishop.png";
import styled from "@emotion/styled";
import { useRef } from "react";

const IntroBishop = ({ startModelClose }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);

  const variants = {
    visible: {
      opacity: 1,
      translateX: "65%",
      transition: {
        duration: 0.5,
      },
    },
    hidden: {
      opacity: 0,
      translateX: "70%",
    },
  };
  return (
    <>
      <IntroBishopWrapper
        ref={ref}
        animate={startModelClose && isInView ? "visible" : "hidden"}
        variants={variants}
      >
        <img src={IntroBishopImg} alt={"bishop"} />
      </IntroBishopWrapper>
    </>
  );
};

const IntroBishopWrapper = styled(motion.div)`
  position: absolute;
  pointer-events: none;
  z-index: 1;
`;

export default IntroBishop;
