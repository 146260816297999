import styled from "@emotion/styled";

const DownloadButtons = () => {
  return (
    <DownloadButtonsWrapper>
      <DownloadButtonImg src={"/images/버튼아님.png"} />
      <DownloadButton
        href={"https://discord.maplegenesis.com/"}
        target={"_blank"}
      >
        <DownloadButtonImg className={"show"} src={"/images/다운로드.png"} />
        <DownloadButtonImg
          className={"hover"}
          src={"/images/다운로드 (오버레이).png"}
        />
      </DownloadButton>
      <DownloadButton
        href={"https://discord.maplegenesis.com/"}
        target={"_blank"}
      >
        <DownloadButtonImg className={"show"} src={"/images/디스코드.png"} />
        <DownloadButtonImg
          className={"hover"}
          src={"/images/디스코드 (오버레이).png"}
        />
      </DownloadButton>
    </DownloadButtonsWrapper>
  );
};

const DownloadButtonsWrapper = styled.div`
  position: absolute;
  top: 2rem;
  right: 1rem;
  z-index: 999;
`;

const DownloadButtonImg = styled.img`
  display: block;
  margin-bottom: 4px;
`;

const DownloadButton = styled.a`
  .show {
    display: block;
  }
  .hover {
    display: none;
  }
  :hover {
    cursor: pointer;
    .show {
      display: none;
    }
    .hover {
      display: block;
    }
  }
`;

export default DownloadButtons;
