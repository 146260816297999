import MainVideo from "./MainVideo";
import GenesisLogo from "./GenesisLogo";
import styled from "@emotion/styled";
import Animations from "./Animations";
import MainBackMusic from "./MainBackMusic";
import DownloadButtons from "../overlay/DownloadButtons";
import HeaderLights from "./AnimationCompnents/HeaderLights";
import { useRecoilState } from "recoil";
import startModalCloseState from "../../store/startModalCloseState";
import FlagBackImg from "../../assets/images/flag/flag_back.png";

const Header = ({ forwardRef }) => {
  const [startModelClose, setStartModalClose] =
    useRecoilState(startModalCloseState);
  return (
    <HeaderWrapper ref={forwardRef}>
      <MainBackMusic />
      <MainVideo />
      <GenesisLogo />
      <FlagBack src={FlagBackImg} />
      {startModelClose && <Animations />}
      <HeaderBackground src={"/images/header_background.png"} />
      <HeaderLine src={"/images/header_line.png"} />
      <DownloadButtons />
      <HeaderFilter src={"/images/header_filter.png"} />
      <HeaderLights />
    </HeaderWrapper>
  );
};

const FlagBack = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 32%;
  z-index: -2;
`;

const HeaderWrapper = styled.header`
  position: relative;
  width: 100%;
  overflow: hidden;
  aspect-ratio: 16 / 9;
`;

const HeaderBackground = styled.img`
  position: absolute;
  bottom: 0;
  height: 50%;
  width: 100%;
  object-fit: fill;
  z-index: -3;
`;

const HeaderFilter = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
`;

const HeaderLine = styled.img`
  position: absolute;
  bottom: 4%;
  width: 100%;
  z-index: -3;
`;

export default Header;
