import FlagBackImg from "../../assets/images/flag/flag_back.png";
import FlagCharacterImg from "../../assets/images/flag/flag_character.png";
import Flag1Img from "../../assets/images/flag/flag1.png";
import Flag2Img from "../../assets/images/flag/flag2.png";
import Flag3Img from "../../assets/images/flag/flag3.png";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import AnimatedLogo from "./AnimationCompnents/AnimatedLogo";

const Animations = () => {
  return (
    <>
      <FlagCharacter
        src={FlagCharacterImg}
        initial={{ opacity: 0, left: "6%" }}
        animate={{ opacity: 1, left: "4%" }}
        transition={{ duration: 0.6, ease: "easeInOut", delay: 0.6 }}
      />
      <FirstFlag
        src={Flag1Img}
        initial={{ scale: 2, rotateX: "180deg" }}
        animate={{ scale: 1, rotateX: "0deg" }}
        transition={{ duration: 0.6, ease: "easeInOut", delay: 0 }}
      />
      <SecondFlag
        src={Flag2Img}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.4, ease: "easeInOut", delay: 0.2 }}
      />
      <ThirdFlag
        src={Flag3Img}
        initial={{ scale: 1, rotateX: "180deg" }}
        animate={{ scale: 1, rotateX: "0deg" }}
        transition={{ duration: 0.4, ease: "easeInOut", delay: 0.4 }}
      />
      <AnimatedLogo />
    </>
  );
};

const FlagCharacter = styled(motion.img)`
  position: absolute;
  left: 4%;
  top: 8%;
  width: 44%;
  z-index: 1;
`;

const FirstFlag = styled(motion.img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 25%;
  transform-origin: center top;
  z-index: -1;
`;

const SecondFlag = styled(motion.img)`
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  z-index: -5;
`;

const ThirdFlag = styled(motion.img)`
  position: absolute;
  top: 0;
  right: 0;
  height: 97%;
  transform-origin: center top;
  z-index: -1;
`;

export default Animations;
