import SectionWrapper from "../common/SectionWrapper";
import styled from "@emotion/styled";
import BackgroundImg from "../../assets/images/section/section4_background.png";
import SectionBorderShadow from "../common/SectionBorderShadow";
import { FlexCenterContainer } from "../common/Container";
import ContentTitleImg from "../../assets/images/content/title.png";

import ThemeImg from "../../assets/images/content/thema_dungeon.png";
import SummonerImg from "../../assets/images/content/sohawnsa.png";
import SmashImg from "../../assets/images/content/smash.png";
import TowerImg from "../../assets/images/content/tower_of_death.png";
import RevolImg from "../../assets/images/content/revolution.png";
import NewChImg from "../../assets/images/content/newcheryeong.png";

import PaladinImg from "../../assets/images/character/paladin.png";
import CaptainImg from "../../assets/images/character/captain.png";

import BottomImg from "../../assets/images/content/bottom.png";

import { useRef, useState } from "react";
import ContentCarousel from "./ContentCarousel";
import { motion, useInView } from "framer-motion";

const ContentSection = ({ forwardRef }) => {
  const [page, setPage] = useState(0);

  const paladinRef = useRef(null);
  const captainRef = useRef(null);

  const paladinIsInView = useInView(paladinRef);
  const captainIsInView = useInView(captainRef);

  const imageList = [
    ThemeImg,
    SummonerImg,
    SmashImg,
    TowerImg,
    RevolImg,
    NewChImg,
  ];

  const paladinVariants = {
    visible: {
      opacity: 1,
      translateX: "0%",
      transition: {
        duration: 0.5,
      },
    },
    hidden: {
      opacity: 0,
      translateX: "-10%",
    },
  };

  const captainVariants = {
    visible: {
      opacity: 1,
      translateX: "0%",
      transition: {
        duration: 0.5,
        delay: 0.25,
      },
    },
    hidden: {
      opacity: 0,
      translateX: "10%",
    },
  };

  return (
    <>
      <SectionWrapper ref={forwardRef}>
        <Background />
        <SectionBorderShadow />
        <Paladin
          variants={paladinVariants}
          ref={paladinRef}
          animate={paladinIsInView ? "visible" : "hidden"}
          src={PaladinImg}
        />
        <Captain
          variants={captainVariants}
          ref={captainRef}
          animate={captainIsInView ? "visible" : "hidden"}
          src={CaptainImg}
        />
        <FlexCenterContainer>
          <ContentTitle src={ContentTitleImg} />
          <SliderTitle src={imageList[page]} />
          <ContentCarousel page={page} setPage={setPage} />
        </FlexCenterContainer>
        <ContentBottom src={BottomImg} />
      </SectionWrapper>
    </>
  );
};

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(${BackgroundImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: -120px;
  z-index: -100;
`;

const ContentTitle = styled.img`
  margin-bottom: 1rem;
  object-fit: cover;
`;

const SliderTitle = styled.img`
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

const ContentBottom = styled.img`
  margin-top: 4rem;
`;

const Paladin = styled(motion.img)`
  position: absolute;
  right: 65%;
`;
const Captain = styled(motion.img)`
  position: absolute;
  left: 65%;
`;

export default ContentSection;
