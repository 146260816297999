import { AnimatedLight } from "./animationStyled";
import styled from "@emotion/styled";
import {
  createLightAnimation,
  defaultLighting,
} from "./animationHelperFunctions";

const HeaderLights = () => {
  return (
    <HeaderLightWrapper>
      <AnimatedLight
        src={"/images/lighting/4.png"}
        initial={{
          ...createLightAnimation({
            bottom: "50%",
            right: "12%",
            scale: 1,
          }),
          translateX: "50%",
          translateY: "50%",
        }}
        {...defaultLighting(1, 2)}
      />
      <AnimatedLight
        src={"/images/lighting/5.png"}
        initial={{
          ...createLightAnimation({
            top: "17%",
            right: "9%",
            scale: 1,
          }),
          translateX: "50%",
          translateY: "50%",
        }}
        {...defaultLighting(2.5, 3)}
      />
    </HeaderLightWrapper>
  );
};

const HeaderLightWrapper = styled.div`
  top: 0;
  right: 0;
`;

const CenterAnimatedLight = styled(AnimatedLight)``;

export default HeaderLights;
