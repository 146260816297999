import styled from "@emotion/styled";

const Container = styled.div`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
`;
export const FlexCenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
`;

export default Container;
