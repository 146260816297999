import GenesisLogoImage from "../../assets/images/flag/genesis_logo.png";
import styled from "@emotion/styled";

const GenesisLogo = () => {
  return <GenesisLogoComp src={GenesisLogoImage} />;
};

const GenesisLogoComp = styled.img`
  position: absolute;
  top: 2rem;
  left: 2rem;
  width: 10%;
  min-width: 100px;
  max-width: 200px;
  z-index: 99;
`;

export default GenesisLogo;
