import MainMusic from "../../assets/audio/main_bgm.MP3";
import { useEffect, useRef } from "react";
import ReactHowler from "react-howler";
import { useRecoilState, useRecoilValue } from "recoil";
import bgmPlayStore from "../../store/bgmPlayStore";
import bgmPlayMasterState from "../../store/bgmPlayMasterState";

const MainBackMusic = () => {
  const bgmPlay = useRecoilValue(bgmPlayStore);
  const bgmPlayMaster = useRecoilValue(bgmPlayMasterState);
  return (
    <>
      <ReactHowler
        src={MainMusic}
        playing={bgmPlay && bgmPlayMaster}
        loop={true}
        volume={0.5}
      />
    </>
  );
};

export default MainBackMusic;
