import { GiSpeaker, GiSpeakerOff } from "react-icons/gi";
import styled from "@emotion/styled";
import { useRecoilState } from "recoil";
import bgmPlayMasterState from "../../store/bgmPlayMasterState";

const SoundButton = () => {
  const [bgmPlayMaster, setBgmPlayerMaster] =
    useRecoilState(bgmPlayMasterState);
  return (
    <SoundButtonWrapper>
      <SoundButtonComp onClick={() => setBgmPlayerMaster((p) => !p)}>
        {bgmPlayMaster ? (
          <GiSpeaker color={"#ffffffdd"} size={32} />
        ) : (
          <GiSpeakerOff color={"#ffffffdd"} size={32} />
        )}
      </SoundButtonComp>
    </SoundButtonWrapper>
  );
};

const SoundButtonWrapper = styled.div`
  position: relative;
  padding-top: 0.5rem;
  display: flex;
  justify-content: flex-end;
  right: 0;
`;

const SoundButtonComp = styled.div`
  padding: 0.25rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: scale 0.1s ease;
  :hover {
    scale: 1.1;
    transition: scale 0.1s ease;
  }
`;

export default SoundButton;
