import Header from "../components/header/Header";
import styled from "@emotion/styled";
import ContentOverlay from "../components/overlay/ContentOverlay";
import IntroSection from "../components/intro/IntroSection";
import MagSection from "../components/magnification/MagSection";
import JobSection from "../components/job/JobSection";
import ContentSection from "../components/content/ContentSection";
import { useEffect, useRef, useState } from "react";
import CreationSection from "../components/creation/CreationSection";
import LastBottomImg2 from "../assets/images/last_bottom2.png";
import Modal from "../components/common/Modal";
import { useRecoilState } from "recoil";
import bgmPlayStore from "../store/bgmPlayStore";
import startModalCloseState from "../store/startModalCloseState";

import StartButtonImg from "../assets/images/start_button.png";
import StartButtonOverImg from "../assets/images/start_button_over.png";
import StartMainImg from "../assets/images/start_img.gif";
import effectSound from "../funcs/effectSound";

import ScrollUpMP3 from "../assets/audio/scrollup.mp3";
import { AnimatePresence } from "framer-motion";

const MainPage = () => {
  const headerRef = useRef(null);
  const introRef = useRef(null);
  const jobRef = useRef(null);
  const contentRef = useRef(null);
  const creationRef = useRef(null);

  const [startVideo, setStartVideo] = useState(true);
  const [bgmPlay, setBgmPlay] = useRecoilState(bgmPlayStore);
  const [startModelClose, setStartModalClose] =
    useRecoilState(startModalCloseState);

  const refs = {
    header: headerRef,
    intro: introRef,
    job: jobRef,
    content: contentRef,
    creation: creationRef,
  };

  return (
    <MainPageWrapper>
      <ContentOverlay refs={refs} />
      <Header forwardRef={refs.header} />
      <IntroSection forwardRef={refs.intro} />
      <MagSection />
      <JobSection forwardRef={refs.job} />
      <ContentSection forwardRef={refs.content} />
      <CreationSection forwardRef={refs.creation} />
      <footer>
        <FooterImg src={LastBottomImg2} />
      </footer>
      <AnimatePresence>
        {startVideo && (
          <Modal
            key={"myModal"}
            closeFunc={() => {
              setStartVideo(false);
              setBgmPlay(true);
              setStartModalClose(true);

              document.body.style.overflow = "auto";
            }}
            closeButton={false}
            bgOpacity={0.8}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.25 }}
          >
            <img src={StartMainImg} />
            <StartButton />
          </Modal>
        )}
      </AnimatePresence>
    </MainPageWrapper>
  );
};

const MainPageWrapper = styled.div`
  position: relative;
  max-width: 2560px;
  min-width: 1280px;
  width: 100%;

  margin: 0 auto;

  overflow-x: hidden;
`;

const FooterImg = styled.img`
  width: 100%;
`;

const StartButton = styled.div`
  margin-top: 1rem;
  background-image: url(${StartButtonImg});
  width: 303px;
  height: 50px;

  :hover {
    cursor: pointer;
    background-image: url(${StartButtonOverImg});
  }
`;

export default MainPage;
