import styled from "@emotion/styled";

const SectionWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 10rem 0 0 0;
  overflow: hidden;

  box-sizing: border-box;

  &:last-of-type {
    padding-bottom: 10rem;
  }
`;

export default SectionWrapper;
