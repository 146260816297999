import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Global } from "@emotion/react";
import globalStyles from "./styles/globalStyles";
import { RecoilRoot } from "recoil";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <Global styles={globalStyles} />
      <App />
    </RecoilRoot>
  </React.StrictMode>
);
