import styled from "@emotion/styled";
import { Stream } from "@cloudflare/stream-react";
import { useRecoilState } from "recoil";
import startModalCloseState from "../../store/startModalCloseState";
import { useEffect, useRef } from "react";

const MainVideo = () => {
  const myRef = useRef(null);
  const [startModelClose, setStartModalClose] =
    useRecoilState(startModalCloseState);

  useEffect(() => {
    if (startModelClose) {
      if (myRef != null) {
        myRef.current.play();
      }
    }
  }, [startModelClose]);
  return (
    <MainVideoWrapper>
      <MainVideoFilter />
      <MainVideoComp
        ref={myRef}
        src={
          "https://customer-l1ydej96f92z3tcu.cloudflarestream.com/32a99b87981b6d8b35b62f63d2ece09d/downloads/default.mp4"
        }
        muted={true}
        loop={true}
      />
    </MainVideoWrapper>
  );
};

const MainVideoWrapper = styled.div`
  position: relative;
  width: 80%;
  left: 50%;
  transform: translate(-50%, -5%);
  z-index: -100;
`;

const MainVideoComp = styled.video`
  position: relative;
  width: 100%;
  z-index: -2;
`;

const MainVideoFilter = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background: black;
  z-index: -1;
`;

export default MainVideo;
