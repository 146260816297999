import SectionBorderShadowImg from "../../assets/images/border_shadow.png";

import styled from "@emotion/styled";

const SectionBorderShadow = () => {
  return (
    <>
      <SectionBorderShadowComp />
    </>
  );
};

const SectionBorderShadowComp = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${SectionBorderShadowImg});
  z-index: -50;
`;

export default SectionBorderShadow;
