import BackgroundImg from "../../assets/images/section/section2_background.png";
import styled from "@emotion/styled";
import SectionBorderShadow from "../common/SectionBorderShadow";
import MagTitleImg from "../../assets/images/mag/title.png";
import MagHuntImg from "../../assets/images/mag/hunt.png";
import MagQuestImg from "../../assets/images/mag/quest.png";
import MagMesoImg from "../../assets/images/mag/meso.png";
import MagItemImg from "../../assets/images/mag/item_drop.png";
import MagBottomImg from "../../assets/images/mag/bottom.png";
import { motion, useInView } from "framer-motion";
import { useRef } from "react";
import _ from "lodash";
import Container from "../common/Container";

const MagSection = () => {
  const huntRef = useRef(null);
  const questRef = useRef(null);
  const mesoRef = useRef(null);
  const itemRef = useRef(null);

  const huntIsInView = useInView(huntRef);
  const questIsInView = useInView(questRef);
  const mesoIsInView = useInView(mesoRef);
  const itemIsInView = useInView(itemRef);

  const variants = {
    visible: {
      opacity: 1,
      translateY: "0%",
      transition: {
        duration: 0.5,
      },
    },
    hidden: {
      opacity: 0,
      translateY: "10%",
    },
  };

  const createVariants = (d) => {
    const copy = _.cloneDeep(variants);
    copy.visible.transition.delay = d;

    return copy;
  };

  return (
    <SectionWrapper>
      <Background />
      <SectionBorderShadow />
      <Container>
        <MagTitle src={MagTitleImg} />
        <MagContents>
          <MagItem
            variants={createVariants(0)}
            ref={huntRef}
            animate={huntIsInView ? "visible" : "hidden"}
            src={MagHuntImg}
          />
          <MagItem
            variants={createVariants(0.25)}
            ref={questRef}
            animate={questIsInView ? "visible" : "hidden"}
            src={MagQuestImg}
          />
          <MagItem
            variants={createVariants(0.5)}
            ref={mesoRef}
            animate={mesoIsInView ? "visible" : "hidden"}
            src={MagMesoImg}
          />
          <MagItem
            variants={createVariants(0.75)}
            ref={itemRef}
            animate={itemIsInView ? "visible" : "hidden"}
            src={MagItemImg}
          />
        </MagContents>
        <MagBottom src={MagBottomImg} />
      </Container>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10rem 0 0 0;
  overflow: hidden;
  box-sizing: border-box;
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(${BackgroundImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  z-index: -100;
`;

const MagTitle = styled.img`
  margin-bottom: 1rem;
  object-fit: cover;
  width: 100%;
  height: 80px;
`;

const MagContents = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  width: 100%;
`;

const MagItem = styled(motion.img)`
  width: 24%;

  :last-child {
    margin-right: 0;
  }
`;

const MagBottom = styled.img`
  object-fit: cover;
  width: 100%;
  height: 80px;
`;

export default MagSection;
