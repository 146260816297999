export const createLightAnimation = ({
  left,
  right,
  top,
  bottom,
  w,
  h,
  scale = 0,
}) => {
  const origin = {
    opacity: 0,
    scale,
    width: w,
    height: h,
  };

  if (left != null) origin.left = left;
  if (right != null) origin.right = right;
  if (top != null) origin.top = top;
  if (bottom != null) origin.bottom = bottom;

  return origin;
};

export const defaultLighting = (duration, delay = 4) => ({
  animate: { opacity: 1, scale: 1 },
  transition: {
    duration,
    ease: "linear",
    delay,
    repeat: Infinity,
    repeatType: "mirror",
    repeatDelay: 0.5,
  },
});
