import styled from "@emotion/styled";
import IntroShield from "./IntroShield";
import IntroTitleImg from "../../assets/images/intro/intro_title.png";
import BackgroundImg from "../../assets/images/section/section1_background.png";
import IntroBishop from "./IntroBishop";
import SectionBorderShadow from "../common/SectionBorderShadow";
import { useRecoilState } from "recoil";
import startModalCloseState from "../../store/startModalCloseState";

const IntroSection = ({ forwardRef }) => {
  const [startModelClose, setStartModalClose] =
    useRecoilState(startModalCloseState);
  return (
    <SectionWrapper ref={forwardRef}>
      <IntroBackground />
      <SectionBorderShadow />
      <Title src={IntroTitleImg} />
      <IntroShield startModelClose={startModelClose} />
      <IntroBishop startModelClose={startModelClose} />
    </SectionWrapper>
  );
};

const SectionWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 0 10rem 0;

  width: 100%;
`;

const Title = styled.img`
  margin-bottom: 2rem;
`;

const IntroBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(${BackgroundImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 0px;
  background-position-x: center;
  z-index: -100;
`;

export default IntroSection;
