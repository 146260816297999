import styled from "@emotion/styled";
import SectionWrapper from "../common/SectionWrapper";
import BackgroundImg from "../../assets/images/section/section3_background.png";
import SectionBorderShadow from "../common/SectionBorderShadow";
import { FlexCenterContainer } from "../common/Container";
import JobTitleImg from "../../assets/images/job/title.png";
import JobContent from "./JobContent";
import SignusImg from "../../assets/images/character/signus.png";
import { motion, useInView } from "framer-motion";
import { useRef } from "react";

const JobSection = ({ forwardRef }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);

  const variants = {
    visible: {
      opacity: 1,
      translateX: "0%",
      transition: {
        duration: 0.5,
      },
    },
    hidden: {
      opacity: 0,
      translateX: "5%",
    },
  };

  return (
    <SectionWrapper ref={forwardRef}>
      <Background />
      <SectionBorderShadow />
      <Signus
        src={SignusImg}
        ref={ref}
        variants={variants}
        animate={isInView ? "visible" : "hidden"}
      />
      <FlexCenterContainer>
        <JobTitle src={JobTitleImg} />
        <JobContent />
      </FlexCenterContainer>
    </SectionWrapper>
  );
};

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(${BackgroundImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: 160px;
  z-index: -100;
`;

const Signus = styled(motion.img)`
  position: absolute;
  left: 50%;
  z-index: -20;
`;

const JobTitle = styled.img`
  margin-bottom: 1rem;
  object-fit: cover;
`;

export default JobSection;
