import styled from "@emotion/styled";
import { SiDiscord } from "react-icons/si";
import { useScroll } from "framer-motion";
import { useCallback, useEffect, useState } from "react";
import SoundButton from "./SoundButton";
import { debounce } from "lodash/function";

const ContentOverlay = ({ refs }) => {
  let [centerIndex, setCenterIndex] = useState(0);
  let lastScrollTop = 0;
  let lastIndex = 0;

  const debounceMousePosition = useCallback(
    debounce((v, minIndex) => {
      // console.log(lastScrollTop, v, lastIndex, minIndex);
      if (Math.abs(lastScrollTop - v) > 20) {
        if (lastIndex === minIndex) {
          if (lastScrollTop > v) {
            if (lastIndex !== 0) {
              // console.log("move to index", lastIndex - 1);
              Object.values(refs)[lastIndex - 1].current.scrollIntoView({
                behavior: "smooth",
              });
            }
          } else {
            if (lastIndex !== refs.length - 1) {
              // console.log("move to index", lastIndex + 1);
              Object.values(refs)[lastIndex + 1].current.scrollIntoView({
                behavior: "smooth",
              });
            }
          }
        } else {
          Object.values(refs)[minIndex].current.scrollIntoView({
            behavior: "smooth",
          });
        }
      }
      lastScrollTop = v;
      lastIndex = minIndex;
    }, 500),
    []
  );

  const centerIndexCalc = () => {
    let x = window.innerWidth / 2 + window.scrollX;
    let y = window.innerHeight / 2 + window.scrollY;
    let minDist = 50000;
    let minIndex = -1;
    for (let i = 0; i < Object.keys(refs).length; i += 1) {
      let targetNode = Object.values(refs)[i].current;
      let centerX = targetNode.offsetLeft + targetNode.offsetWidth / 2;
      let centerY = targetNode.offsetTop + targetNode.offsetHeight / 2;
      let distance = Math.abs(x - centerX) + Math.abs(y - centerY);
      // console.log("distance from ", i, distance);
      if (distance < minDist) {
        minDist = distance;
        minIndex = i;
      }
    }
    setCenterIndex(minIndex);

    return minIndex;
  };

  const calcDragDirection = (e, minIndex) => {
    const v = document.documentElement.scrollTop;
    debounceMousePosition(v, minIndex);
  };

  const docScrollHandler = (e) => {
    const minIndex = centerIndexCalc();
    // calcDragDirection(e, minIndex);
  };

  useEffect(() => {
    document.addEventListener("scroll", docScrollHandler);

    return () => {
      document.removeEventListener("scroll", docScrollHandler);
    };
  }, []);

  return (
    <ContentOverlayWrapper>
      <ContentOverlayHeader>GENESIS</ContentOverlayHeader>
      <ContentOverlayItemWrapper>
        <ContentOverlayItem
          onClick={() =>
            refs.header.current.scrollIntoView({ behavior: "smooth" })
          }
          centerIndex={centerIndex}
          currentIndex={0}
        >
          <ItemIcon>
            <SiDiscord color={"white"} size={24} />
          </ItemIcon>
          <p>
            제네시스
            <br />
            디스코드
          </p>
        </ContentOverlayItem>
        <ContentOverlayItemSplitter />
        <ContentOverlayItem
          onClick={() =>
            refs.intro.current.scrollIntoView({ behavior: "smooth" })
          }
          centerIndex={centerIndex}
          currentIndex={1}
        >
          <p>
            서버소개&
            <br />
            배율확인
          </p>
        </ContentOverlayItem>
        <ContentOverlayItemSplitter />
        <ContentOverlayItem
          onClick={() =>
            refs.job.current.scrollIntoView({ behavior: "smooth" })
          }
          centerIndex={centerIndex}
          currentIndex={2}
        >
          <p>
            제네시스
            <br />
            직업군
          </p>
        </ContentOverlayItem>
        <ContentOverlayItemSplitter />
        <ContentOverlayItem
          onClick={() =>
            refs.content.current.scrollIntoView({ behavior: "smooth" })
          }
          centerIndex={centerIndex}
          currentIndex={3}
        >
          <p>
            서버제작
            <br />
            컨텐츠
          </p>
        </ContentOverlayItem>
        <ContentOverlayItemSplitter />
        <ContentOverlayItem
          onClick={() =>
            refs.creation.current.scrollIntoView({ behavior: "smooth" })
          }
          centerIndex={centerIndex}
          currentIndex={4}
        >
          <p>
            창작&편의
            <br />
            시스템
          </p>
        </ContentOverlayItem>
      </ContentOverlayItemWrapper>
      <GoToTop
        onClick={() =>
          refs.header.current.scrollIntoView({ behavior: "smooth" })
        }
      >
        위로가기
      </GoToTop>
      <SoundButton />
    </ContentOverlayWrapper>
  );
};

const ContentOverlayWrapper = styled.div`
  text-align: center;
  position: fixed;
  z-index: 9999;
  top: 50vh;
  transform: translate(0, -50%);
  right: 1rem;
`;

const ContentOverlayHeader = styled.div`
  background: #680e0e;
  padding: 0.5rem 1.2rem;
  position: relative;
  border: 2px solid #790e0e;
  clip-path: polygon(0 1.2rem, 1.2rem 0, 100% 0, 100% 100%, 0 100%);
  color: #ffffff;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 0.8rem;
  letter-spacing: 1px;

  text-shadow: 0 0 8px #000000aa;
`;

const ContentOverlayItemWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: sans-serif;
  font-weight: 600;
  font-size: 0.8rem;
  color: white;
  border: 1px solid #bb0909;
  background: #b10606;
  box-sizing: border-box;
`;

const ContentOverlayItem = styled.div`
  padding: 0.5rem 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 1px;
  line-height: 1.1rem;
  width: 100%;
  background: ${(props) =>
    props.centerIndex === props.currentIndex ? "#8a0303" : "#b10606"};
  box-sizing: border-box;

  text-shadow: 0 0 4px #000000aa;

  :hover {
    cursor: pointer;
    transition: background-color 0.1s ease;
    background-color: #8a0303;
  }
`;

const GoToTop = styled.div`
  padding: 0.5rem 1.2rem;
  background-color: white;
  justify-content: center;
  flex-direction: column;
  font-family: sans-serif;
  font-weight: 600;
  font-size: 0.8rem;
  color: #b10606;

  :hover {
    cursor: pointer;
    transition: background-color 0.25s ease;
    background-color: white;
  }
`;

const ContentOverlayItemSplitter = styled.div`
  height: 2px;
  background-color: #da0909;
  width: 80%;
`;

const ItemIcon = styled.div`
  margin-right: 0.5rem;

  svg {
    filter: drop-shadow(0 0 4px #000000aa);
  }
`;

export default ContentOverlay;
