import JobMeleeTitleImg from "../../assets/images/job/melee_title.png";
import JobMeleeImageImg from "../../assets/images/job/melee_image.png";
import JobMeleeViewOverImg from "../../assets/images/job/melee_view_over.png";
import JobMeleeViewImg from "../../assets/images/job/melee_view.png";
import JobVersus from "../../assets/images/job/center_versus.png";
import JobMagicTitleImg from "../../assets/images/job/magic_title.png";
import JobMagicImageImg from "../../assets/images/job/magic_image.png";
import JobMagicViewOverImg from "../../assets/images/job/magic_view_over.png";
import JobMagicViewImg from "../../assets/images/job/magic_view.png";
import JobListTopImg from "../../assets/images/job/top.png";
import JobListMeleeHintImg from "../../assets/images/job/melee_hint.png";
import JobListMagicHintImg from "../../assets/images/job/magic_hint.png";
import JobListCharBackImg from "../../assets/images/job/melee_back.png";

import AttackImg1 from "../../assets/images/job/attacker/attacker_img_1.png";
import AttackImg2 from "../../assets/images/job/attacker/attacker_img_2.png";
import AttackImg3 from "../../assets/images/job/attacker/attacker_img_3.png";
import AttackImg4 from "../../assets/images/job/attacker/attacker_img_4.png";
import AttackImg5 from "../../assets/images/job/attacker/attacker_img_5.png";
import AttackImg6 from "../../assets/images/job/attacker/attacker_img_6.png";
import AttackImg7 from "../../assets/images/job/attacker/attacker_img_7.png";
import AttackImg8 from "../../assets/images/job/attacker/attacker_img_8.png";
import AttackImg9 from "../../assets/images/job/attacker/attacker_img_9.png";
import AttackImg10 from "../../assets/images/job/attacker/attacker_img_10.png";
import AttackImg11 from "../../assets/images/job/attacker/attacker_img_11.png";
import AttackImg12 from "../../assets/images/job/attacker/attacker_img_12.png";
import AttackImg13 from "../../assets/images/job/attacker/attacker_img_13.png";

import MagictionImg1 from "../../assets/images/job/magiction/magiction_img_1.png";
import MagictionImg2 from "../../assets/images/job/magiction/magiction_img_2.png";
import MagictionImg3 from "../../assets/images/job/magiction/magiction_img_3.png";
import MagictionImg4 from "../../assets/images/job/magiction/magiction_img_4.png";

import PosterAttacker from "../../assets/images/posters/attacker.png";
import PosterMagiction from "../../assets/images/posters/magction.png";

import styled from "@emotion/styled";
import useMouseOver from "../../hooks/useMouseOver";
import { useEffect, useRef, useState } from "react";
import { motion, useInView } from "framer-motion";
import Modal from "../common/Modal";
import { useRecoilState } from "recoil";
import bgmPlayStore from "../../store/bgmPlayStore";

const JobContent = () => {
  const [meleeRef, meleeIsEnter] = useMouseOver();
  const [magicRef, magicIsEnter] = useMouseOver();
  const [bgmPlay, setBgmPlay] = useRecoilState(bgmPlayStore);

  const attackList = [
    AttackImg1,
    AttackImg2,
    AttackImg3,
    AttackImg4,
    AttackImg5,
    AttackImg6,
    AttackImg7,
    AttackImg8,
    AttackImg9,
    AttackImg10,
    AttackImg11,
    AttackImg12,
    AttackImg13,
  ];

  const magictionList = [
    MagictionImg1,
    MagictionImg2,
    MagictionImg3,
    MagictionImg4,
  ];

  const [dark, setDark] = useState({
    attacker: false,
    magiction: false,
  });

  const [showAttackerVideo, setShowAttackerVideo] = useState(false);
  const [showMagictionVideo, setShowMagictionVideo] = useState(false);

  useEffect(() => {}, [showAttackerVideo, showMagictionVideo]);

  const darkOverride = (name, value) => {
    setDark((o) => ({ ...o, [name]: value }));
  };

  const onPlayHandler = (e) => {
    setBgmPlay(false);
  };

  const onPauseHandler = (e) => {
    setBgmPlay(true);
  };

  const attackerRef = useRef(null);
  const magictionRef = useRef(null);
  const atIsInView = useInView(attackerRef);
  const maIsInView = useInView(magictionRef);

  const atVariants = {
    visible: {
      opacity: 1,
      translateY: "0%",
      transition: {
        duration: 0.5,
      },
    },
    hidden: {
      opacity: 0,
      translateY: "5%",
    },
  };

  const maVariants = {
    visible: {
      opacity: 1,
      translateY: "0%",
      transition: {
        duration: 0.5,
        delay: 0.25,
      },
    },
    hidden: {
      opacity: 0,
      translateY: "5%",
    },
  };

  return (
    <>
      <JobContentComp>
        <JobVerticalLayout
          variants={atVariants}
          ref={attackerRef}
          animate={atIsInView ? "visible" : "hidden"}
        >
          <JobVerticalLayoutItem src={JobMeleeTitleImg} />
          <JobVerticalLayoutItem src={JobMeleeImageImg} />
          <JobVerticalLayoutItem
            onClick={() => setShowAttackerVideo(true)}
            onMouseEnter={() => darkOverride("magiction", true)}
            onMouseLeave={() => darkOverride("magiction", false)}
            style={{ cursor: "pointer" }}
            ref={meleeRef}
            src={meleeIsEnter ? JobMeleeViewOverImg : JobMeleeViewImg}
          />
        </JobVerticalLayout>
        <img src={JobVersus} />
        <JobVerticalLayout
          variants={maVariants}
          ref={magictionRef}
          animate={maIsInView ? "visible" : "hidden"}
        >
          <JobVerticalLayoutItem src={JobMagicTitleImg} />
          <JobVerticalLayoutItem src={JobMagicImageImg} />
          <JobVerticalLayoutItem
            onClick={() => setShowMagictionVideo(true)}
            onMouseEnter={() => darkOverride("attacker", true)}
            onMouseLeave={() => darkOverride("attacker", false)}
            style={{ cursor: "pointer" }}
            ref={magicRef}
            src={magicIsEnter ? JobMagicViewOverImg : JobMagicViewImg}
          />
        </JobVerticalLayout>
      </JobContentComp>
      <JobList>
        <JobListTop>
          <img src={JobListTopImg} />
        </JobListTop>
        <JobListLine>
          <HintImage src={JobListMeleeHintImg} />
          <ImagedDiv isDark={dark.attacker}>
            <ImagedDivBackground src={JobListCharBackImg} />
            {attackList.map((i, idx) => (
              <img key={idx} className={"jobs"} src={i} />
            ))}
          </ImagedDiv>
        </JobListLine>
        <JobListLine>
          <HintImage src={JobListMagicHintImg} />
          <ImagedDiv isDark={dark.magiction}>
            <ImagedDivBackground src={JobListCharBackImg} />
            {magictionList.map((i, idx) => (
              <img key={idx} className={"jobs"} src={i} />
            ))}
          </ImagedDiv>
        </JobListLine>
      </JobList>
      {showAttackerVideo && (
        <Modal
          closeFunc={() => {
            setShowAttackerVideo(false);
            onPauseHandler();
          }}
        >
          <Video
            onClick={(e) => {
              e.stopPropagation();
            }}
            onPlay={onPlayHandler}
            onPause={onPauseHandler}
            controls
            src="https://customer-l1ydej96f92z3tcu.cloudflarestream.com/1ce0012646522a0b64f1219ba2b43ad4/downloads/default.mp4"
            poster={PosterAttacker}
          />
        </Modal>
      )}
      {showMagictionVideo && (
        <Modal
          closeFunc={() => {
            setShowMagictionVideo(false);
            onPauseHandler();
          }}
        >
          <Video
            onClick={(e) => {
              e.stopPropagation();
            }}
            onPlay={onPlayHandler}
            onPause={onPauseHandler}
            controls
            src="https://customer-l1ydej96f92z3tcu.cloudflarestream.com/f46fdb55c9f9eebfc4c522b6704ca262/downloads/default.mp4"
            poster={PosterMagiction}
          />
        </Modal>
      )}
    </>
  );
};

const JobContentComp = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
`;

const JobVerticalLayout = styled(motion.div)`
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
`;

const JobVerticalLayoutItem = styled.img`
  margin-bottom: 2rem;
  :last-child {
    margin-bottom: 0;
  }
`;

const JobList = styled.div`
  position: relative;
  width: 100%;
  padding: 0 1rem;
  img {
    display: block;
  }
`;

const Video = styled.video`
  width: 1050px;
  height: 600px;
`;

const JobListTop = styled.div`
  position: relative;
  margin-bottom: 4px;
  img {
    width: 100%;
  }
`;

const JobListLine = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  margin-bottom: 4px;
  justify-content: space-between;
`;

const HintImage = styled.img`
  width: 15%;
`;

const ImagedDiv = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  img.jobs {
    margin-top: 4px;
    margin-right: 0.5rem;
    height: 75%;

    filter: brightness(${(props) => (props.isDark ? 0.5 : 1)});
    transition: filter 0.25s ease;

    :last-child {
      margin-right: 0;
    }
  }
`;

const ImagedDivBackground = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -100;
`;

export default JobContent;
