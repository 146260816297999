import { useEffect, useRef, useState } from "react";

const useMouseOver = () => {
  const ref = useRef(null);
  const [isEnter, setIsEnter] = useState(false);

  const mouseEnterEvent = () => setIsEnter(true);
  const mouseLeaveEvent = () => setIsEnter(false);

  useEffect(() => {
    if (ref.current != null) {
      ref.current.addEventListener("mouseenter", mouseEnterEvent);
      ref.current.addEventListener("mouseleave", mouseLeaveEvent);
    }

    return () => {
      if (ref.current != null) {
        ref.current.removeEventListener("mouseenter", mouseEnterEvent);
        ref.current.removeEventListener("mouseleave", mouseLeaveEvent);
      }
    };
  }, [ref.current]);

  return [ref, isEnter];
};

export default useMouseOver;
