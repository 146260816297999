import SectionWrapper from "../common/SectionWrapper";
import styled from "@emotion/styled";
import BackgroundImg from "../../assets/images/section/section5_background.png";
import CreationTitleImg from "../../assets/images/creation/title.png";
import SectionBorderShadow from "../common/SectionBorderShadow";
import { FlexCenterContainer } from "../common/Container";
import CreationLineImg1 from "../../assets/images/creation/line1.png";
import CreationLineImg2 from "../../assets/images/creation/line2.png";
import CreationLineImg3 from "../../assets/images/creation/line3.png";
import CreationLineImg4 from "../../assets/images/creation/line4.png";
import CreationLineImg5 from "../../assets/images/creation/line5.png";
import CreationLineImg6 from "../../assets/images/creation/line6.png";
import CreationBottomImg from "../../assets/images/creation/bottom.png";
import { motion, useInView } from "framer-motion";
import { useRef } from "react";
import LastBottomImg1 from "../../assets/images/last_bottom.png";
const CreationSection = ({ forwardRef }) => {
  const refs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const inViews = [
    useInView(refs[0]),
    useInView(refs[1]),
    useInView(refs[2]),
    useInView(refs[3]),
    useInView(refs[4]),
  ];

  const imgs = [
    CreationLineImg1,
    CreationLineImg2,
    CreationLineImg3,
    CreationLineImg4,
    CreationLineImg5,
  ];

  const lineVariants = (d) => ({
    visible: {
      opacity: 1,
      translateY: "0%",
      transition: {
        duration: 0.5,
        delay: 0.25 * d,
      },
    },
    hidden: {
      opacity: 0,
      translateY: "-10%",
    },
  });

  return (
    <>
      <SectionWrapper ref={forwardRef}>
        <Background />
        <SectionBorderShadow />
        <FlexCenterContainer>
          <CreationTitle src={CreationTitleImg} />
          {Array(5)
            .fill(0)
            .map((i, idx) => {
              return (
                <CreationLine
                  key={idx}
                  variants={lineVariants(idx)}
                  src={imgs[idx]}
                  ref={refs[idx]}
                  animate={inViews[idx] ? "visible" : "hidden"}
                />
              );
            })}
          <CreationLine src={CreationLineImg6} />
          <CreationBottom src={CreationBottomImg} />
          <img src={LastBottomImg1} />
        </FlexCenterContainer>
      </SectionWrapper>
    </>
  );
};

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(${BackgroundImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: -250px;
  z-index: -100;
`;

const CreationTitle = styled.img`
  margin-bottom: 4rem;
  object-fit: cover;
`;

const CreationLine = styled(motion.img)`
  margin-bottom: 1rem;
  width: 100%;
`;

const CreationBottom = styled.img`
  margin-bottom: 4rem;
`;

export default CreationSection;
