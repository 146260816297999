import { motion } from "framer-motion";
import styled from "@emotion/styled";
import { nanoid } from "nanoid";
import ServerDescriptionImg from "../../../assets/images/flag/unser_logo.png";
import {
  createLightAnimation,
  defaultLighting,
} from "./animationHelperFunctions";
import { AnimatedLight } from "./animationStyled";

const AnimatedLogo = () => {
  const animationImageList = new Array(15)
    .fill(0)
    .map((i, idx) => `genesis_logo_a_${15 - idx}.png`);
  const imgRoot = "/images/logo_animation";
  const delayRoot = 1.2;

  return (
    <AnimatedLogoWrapper>
      <div style={{ position: "relative" }}>
        {animationImageList
          .slice(0, animationImageList.length - 1)
          .map((i, idx) => (
            <AnimatedLogoComp
              key={nanoid()}
              src={`${imgRoot}/${i}`}
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
              }}
              transition={{
                duration: 0.075,
                ease: "linear",
                delay: 0.075 * idx + delayRoot,
              }}
            />
          ))}
        <AnimatedLogoComp
          src={`${imgRoot}/${
            animationImageList[animationImageList.length - 1]
          }`}
          initial={{ opacity: 0, position: "relative" }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: 0.075,
            ease: "easeInOut",
            delay: 0.075 * (animationImageList.length - 1) + delayRoot,
          }}
        />
        <AnimatedLight
          src={`/images/lighting/1.png`}
          initial={{
            ...createLightAnimation({
              w: "20%",
              top: 0,
              left: 0,
            }),
            translateX: "-12%",
            translateY: "-50%",
          }}
          {...defaultLighting(3, 1)}
        />
        <AnimatedLight
          src={`/images/lighting/2.png`}
          initial={{
            ...createLightAnimation({
              w: "10%",
              bottom: "20%",
              right: 0,
            }),
            translateX: "23%",
          }}
          {...defaultLighting(2.2, 3)}
        />
      </div>
      <ServerDesc
        src={ServerDescriptionImg}
        initial={{
          opacity: 0,
          translateY: 20,
        }}
        animate={{ opacity: 1, translateY: 0 }}
        transition={{ ease: "linear", duration: 1.5, delay: 2.5 }}
      />
      <AnimatedLight
        src={"/images/lighting/3.png"}
        initial={{
          ...createLightAnimation({
            bottom: 0,
            w: "25%",
          }),
        }}
        {...defaultLighting(2, 4)}
      />
    </AnimatedLogoWrapper>
  );
};

const AnimatedLogoComp = styled(motion.img)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  filter: drop-shadow(0px 0px 20px #00000015);
`;

const AnimatedLogoWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 21%;
  left: 38%;
  width: 40%;
  z-index: 2;
`;

const ServerDesc = styled(motion.img)`
  margin-top: 1rem;
  width: 75%;
`;

export default AnimatedLogo;
