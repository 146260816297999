import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

// const { persistAtom } = recoilPersist({
//   key: "bgmPlay",
// });

const bgmPlayMasterState = atom({
  key: "bgmPlayMasterState",
  default: true,
  // effects_UNSTABLE: [persistAtom],
});

export default bgmPlayMasterState;
