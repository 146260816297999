import { createPortal } from "react-dom";
import styled from "@emotion/styled";
import { useEffect } from "react";
import { RiCloseFill } from "react-icons/ri";
import { AnimatePresence, motion } from "framer-motion";

const Modal = (props) => {
  const {
    children,
    closeFunc,
    closeButton = true,
    bgOpacity = 0.6,
    ...defaultProps
  } = props;
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  return createPortal(
    <ModalComp onClick={closeFunc} {...defaultProps}>
      <Background bgOpacity={bgOpacity} />
      <ModalChild>{children}</ModalChild>
      {closeButton && (
        <CloseButton onClick={closeFunc}>
          <RiCloseFill size={64} />
        </CloseButton>
      )}
    </ModalComp>,
    document.getElementById("modal")
  );
};

const ModalComp = styled(motion.div)`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
`;

const ModalChild = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: ${(props) => (props.bgOpacity ? props.bgOpacity : 0.6)};
  z-index: -1;
`;

const CloseButton = styled.div`
  background-color: #ffffff25;
  position: fixed;
  top: 1rem;
  right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    cursor: pointer;
    background-color: #ffffff50;
  }
`;
export default Modal;
