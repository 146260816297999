import { useEffect, useRef, useState } from "react";
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  DotGroup,
  Slide,
  Slider,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import styled from "@emotion/styled";

import SlideLeftImg from "../../assets/images/content/slide_left.png";
import SlideLeftOverImg from "../../assets/images/content/slide_left_over.png";
import SlideRightImg from "../../assets/images/content/slide_right.png";
import SlideRightOverImg from "../../assets/images/content/slide_right_over.png";
import SlideEmpty from "../../assets/images/content/empty.png";
import SlideFill from "../../assets/images/content/fill.png";

import DotsLeftImg from "../../assets/images/content/dots_left.png";
import DotsRightImg from "../../assets/images/content/dots_right.png";
import { useRecoilState } from "recoil";
import bgmPlayStore from "../../store/bgmPlayStore";

import PosterThemeImg from "../../assets/images/posters/theme.png";
import PosterSummonImg from "../../assets/images/posters/summoner.png";
import PosterSmashImg from "../../assets/images/posters/smash.png";
import PosterTowerImg from "../../assets/images/posters/tower_of_death.png";
import PosterRevolutionImg from "../../assets/images/posters/revloution.png";
import PosterNewImg from "../../assets/images/posters/new.png";

const ContentCarousel = ({ page, setPage }) => {
  const [bgmPlay, setBgmPlay] = useRecoilState(bgmPlayStore);
  const slideRef = useRef(null);
  const videoRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const streamIframeList = [
    "https://customer-l1ydej96f92z3tcu.cloudflarestream.com/585427ae9276ce6f11e5764740a2f27a/downloads/default.mp4", // 테마던전
    "https://customer-l1ydej96f92z3tcu.cloudflarestream.com/a9518791abaa802d5baa5e0f2843049e/downloads/default.mp4", // 소환사의 협곡
    "https://customer-l1ydej96f92z3tcu.cloudflarestream.com/b80fdc9116d900bb3d1e3c41e701f925/downloads/default.mp4", // 스매쉬
    "https://customer-l1ydej96f92z3tcu.cloudflarestream.com/24ed3b4e14040924854b35d56bcc5544/downloads/default.mp4", // 사망의 탑
    "https://customer-l1ydej96f92z3tcu.cloudflarestream.com/36f25147727eea30f9b77cd3e40ba838/downloads/default.mp4", // 레볼루션
    "https://customer-l1ydej96f92z3tcu.cloudflarestream.com/195cd117e6c05d049cb294f1f45959a2/downloads/default.mp4", // 신체령
  ];

  const posterList = [
    PosterThemeImg,
    PosterSummonImg,
    PosterSmashImg,
    PosterTowerImg,
    PosterRevolutionImg,
    PosterNewImg,
  ];

  const allStop = () => {
    videoRefs.forEach((video) => {
      if (video != null) {
        video.current.pause();
        video.current.currentTime = 0;
      }
    });
  };

  const pauseAllVideos = (e, d) => {
    const originValue = slideRef.current
      .getStore()
      .getStoreState().currentSlide;
    if (d == "p") {
      if (originValue + 1 >= streamIframeList.length) {
        setPage(0);
      } else {
        setPage(originValue + 1);
      }
    } else if (d == "m") {
      if (originValue - 1 < 0) {
        setPage(streamIframeList.length - 1);
      } else {
        setPage(originValue - 1);
      }
    }
    allStop();
  };

  const dotClickEvent = (e) => {
    if (!e.target.className.includes("carousel__dot-group")) {
      const splited = e.target.className.split(" ");

      for (let i = 0; i < splited.length; i++) {
        if (splited[i].includes("--")) {
          const value = splited[i].split("--")[1];
          setPage(value);
          break;
        }
      }
      allStop();
    }
  };

  const onPlayHandler = (e) => {
    setBgmPlay(false);
  };

  const onPauseHandler = (e) => {
    setBgmPlay(true);
  };

  return (
    <>
      <CustomCarouselProvider
        ref={slideRef}
        naturalSlideWidth={1050}
        naturalSlideHeight={600}
        totalSlides={streamIframeList.length}
        touchEnabled={false}
        dragEnabled={false}
        infinite={true}
      >
        <CustomSlider>
          {streamIframeList.map((s, idx) => (
            <CustomSlide index={idx} key={idx}>
              <video
                onPlay={onPlayHandler}
                onPause={onPauseHandler}
                poster={posterList[idx]}
                ref={videoRefs[idx]}
                src={s}
                controls={true}
              />
            </CustomSlide>
          ))}
        </CustomSlider>
        <ButtonBack
          onClick={(e) => pauseAllVideos(e, "m")}
          children={null}
        ></ButtonBack>
        <ButtonNext
          onClick={(e) => pauseAllVideos(e, "p")}
          children={null}
        ></ButtonNext>
        <DotGroup onClick={dotClickEvent} />
      </CustomCarouselProvider>
    </>
  );
};

const CustomCarouselProvider = styled(CarouselProvider)`
  position: relative;
  width: 1050px;
  height: 600px;

  video,
  button {
    border: 0;
    outline: none;
  }

  .carousel__back-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -100px;
    width: 75px;
    height: 75px;

    background-color: transparent;
    background-image: url(${SlideLeftImg});
    background-size: cover;

    :hover {
      background-image: url(${SlideLeftOverImg});
    }
  }

  .carousel__next-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -100px;
    width: 75px;
    height: 75px;

    background-color: transparent;
    background-image: url(${SlideRightImg});
    background-size: cover;

    :hover {
      background-image: url(${SlideRightOverImg});
    }
  }

  .carousel__dot-group {
    position: relative;
    width: fit-content;
    padding-top: 1rem;
    left: 50%;
    transform: translate(-50%);

    &::before {
      position: absolute;
      content: " ";
      left: -10px;
      transform: translate(-100%);

      background-size: contain;
      background-repeat: no-repeat;
      background-image: url(${DotsLeftImg});
      background-position: center;
      width: 80px;
      height: 30px;
    }

    &::after {
      position: absolute;
      content: " ";
      right: -10px;
      transform: translate(100%);

      background-size: contain;
      background-repeat: no-repeat;
      background-image: url(${DotsRightImg});
      background-position: center;
      width: 80px;
      height: 30px;
    }

    .carousel__dot {
      width: 30px;
      height: 30px;
      background-color: transparent;
      background-size: cover;
      background-image: url(${SlideEmpty});
      margin: 0 4px;

      &.carousel__dot--selected,
      &:hover {
        background-image: url(${SlideFill});
      }
    }
  }
`;

const CustomSlider = styled(Slider)`
  position: relative;
  width: 1050px;
  height: 600px;
`;

const CustomSlide = styled(Slide)`
  position: relative;
  height: 600px;

  video {
    width: 1050px;
    height: 600px;
  }
`;

export default ContentCarousel;
