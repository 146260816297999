import styled from "@emotion/styled";
import IntroDescTopImg from "../../assets/images/intro/intro_desc_top.png";
import IntroDescMiddleImg from "../../assets/images/intro/intro_desc_middle.png";
import IntroDescBottomImg from "../../assets/images/intro/intro_desc_bottom.png";
import IntroShieldImg from "../../assets/images/intro/intro_shield.png";
import IntroHoverImg1 from "../../assets/images/intro/intro_hover_image_1.png";
import IntroHoverImg2 from "../../assets/images/intro/intro_hover_image_2.png";
import IntroHoverImg3 from "../../assets/images/intro/intro_hover_image_3.png";
import { motion, useInView } from "framer-motion";
import { useRef } from "react";
import _ from "lodash";

const IntroShield = ({ startModelClose }) => {
  const img1Ref = useRef(null);
  const img2Ref = useRef(null);
  const img3Ref = useRef(null);

  const isInView1 = useInView(img1Ref, { amount: 0.1 });
  const isInView2 = useInView(img2Ref, { amount: 0.1 });
  const isInView3 = useInView(img3Ref, { amount: 0.1 });

  const variants = {
    visible: {
      opacity: 1,
      translateY: "0%",
      rotate: ["0deg", "2deg", "-2deg"],
      transition: {
        duration: 0.5,
        rotate: {
          repeat: Infinity,
          repeatType: "mirror",
          duration: 7,
        },
      },
    },
    hidden: {
      opacity: 0,
      translateY: "10%",
    },
  };

  const createVariants = (r) => {
    const copy_variants = _.cloneDeep(variants);
    copy_variants.visible.transition.rotate.duration = r;

    return copy_variants;
  };

  return (
    <IntroShieldWrapper>
      <img src={IntroDescTopImg} />
      <img src={IntroDescMiddleImg} />
      <img src={IntroDescBottomImg} />
      <HoverImg
        variants={createVariants(7)}
        ref={img1Ref}
        animate={startModelClose && isInView1 ? "visible" : "hidden"}
        src={IntroHoverImg1}
        top={"45%"}
        left={"-25%"}
        zIndex={3}
      />
      <HoverImg
        variants={createVariants(12)}
        ref={img2Ref}
        animate={startModelClose && isInView2 ? "visible" : "hidden"}
        src={IntroHoverImg2}
        top={"85%"}
        left={"15%"}
        zIndex={2}
      />
      <HoverImg
        variants={createVariants(9)}
        ref={img3Ref}
        animate={startModelClose && isInView3 ? "visible" : "hidden"}
        src={IntroHoverImg3}
        top={"63%"}
        left={"38%"}
        zindex={1}
      />
    </IntroShieldWrapper>
  );
};

const IntroShieldWrapper = styled.div`
  position: relative;
  display: flex;
  padding: 7rem;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 881px;
  height: 801px;
  background-image: url(${IntroShieldImg});
  box-sizing: border-box;

  img {
    margin: 1rem 0;
  }
`;

const HoverImg = styled(motion.img)`
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  z-index: ${(props) => props.zIndex};
  transition: scale 0.25s ease;

  :hover {
    z-index: 50;
    scale: 1.1;
    transition: scale 0.25s ease;
    cursor: pointer;
  }
`;

export default IntroShield;
